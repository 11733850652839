body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.p-fieldset-legend-text {
    color: #0891b2 !important;
}

.p-tabview .p-tabview-panels {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
}

.p-inputnumber-input {
    text-align: right !important;
}

.p-inputnumber-input:disabled {
    color: #0891b2 !important;
    opacity: 1 !important;
    background-color: #f1f1f1 !important;
    font-weight: 700 !important;
}

.p-inputnumber-input:read-only {
    color: #f1f1f1;
    background-color: dimgray;
}

.p-inputnumber-input:active:read-only {
    box-shadow: 0 0 0 0 #fff !important;
}

.p-inputtext:read-only:hover {
    border-color: rgb(255, 255, 255, 0) !important;
}

.p-inputtext:read-only:focus {
    border-color: rgb(255, 255, 255, 0) !important;
}

.p-inputtext:read-only:focus-visible {
    border-color: rgb(255, 255, 255, 0) !important;
}

.p-inputtext:read-only:active {
    border-color: rgb(255, 255, 255, 0) !important;
}

.p-inputgroup-addon {
    padding: 0rem 0.5rem 0rem 0.5rem !important;
}

.p-inputswitch {
    width: 3.7rem !important;
}

.p-inputswitch .p-inputswitch-slider:before {
    left: 0.2rem !important;
}

.p-fieldset .p-fieldset-content {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}

.p-dropdown-items-wrapper {
    max-height: 360px !important;
    font-size: 0.8rem !important;
}

.p-dropdown-item {
    font-size: 0.8rem !important;
}

.p-fieldset-legend {
    width: 300px !important;
}

.p-fieldset-legend > a {
    justify-content: left !important;
}
